import React, { useEffect, useState }  from 'react';
import 'swiper/css'; 
import axios from 'axios';
import { Link } from 'react-router-dom'; 
import  config  from '../../config';
const ServiceBanner = () =>{
    const [serviceBannerJsonData, setServiceBannerJsonData] = useState ([]); 

    async function fetchServiceBanner() {
        try {
        const response = await axios.get(config.API_URL + config.PAGES_URL);
        setServiceBannerJsonData(response.data[4]);
        } catch (error) {
        console.error('Error reading JSON file:', error);
        }
    }
    useEffect(() => {
        fetchServiceBanner();
      }, []);
    return(
        <>
            <section className="service-banner-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 d-sm-none d-block mb-5">
                            <div className="service-image">
                                <img src={config.FILE_URL+serviceBannerJsonData.thumbImage} alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="banner-content">
                                <div className="section-header">
                                    <h3>{serviceBannerJsonData.title}</h3>
                                    <p dangerouslySetInnerHTML={{ __html: serviceBannerJsonData.content }}></p>
                                </div>
                                <div className="service-offer d-flex justify-content-between flex-sm-nowrap flex-wrap">
                                    <div className="d-flex">
                                        <img src={config.FILE_URL+"images/service/save_cost.png"} alt="" />
                                        <p>Save Cost</p>
                                    </div>
                                    <div className="d-flex">
                                        <img src={config.FILE_URL+"images/service/support.png"} alt="" />
                                        <p>24/7 Support</p>
                                    </div>
                                    <div className="d-flex">
                                        <img src={config.FILE_URL+"images/service/professional.png"} alt="" />
                                        <p>30+  Professionalt</p>
                                    </div>
                                </div>
                                <div className="service-order">
                                    <Link to="../contactpage" className="btn btn-service">Get Quote</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 d-sm-block d-none">
                            <div className="service-image">
                                <img src={config.FILE_URL+serviceBannerJsonData.thumbImage} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>    
            </section>   
        </>
        
    )
}
export default ServiceBanner;