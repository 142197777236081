import React, { useEffect, useState }  from 'react';
import axios from 'axios';
import config from '../../config';

const ChooseArea = () =>{ 
    const [excellenceJasonData, setExcellenceJasonData] = useState([]);
    // const [servicJsonData, setServiceJsonData] = useState ([]);
    async function fatchExcellence() {
        try {
            const response = await axios.get(config.API_URL + 'excellence.json'); // Make sure the path is correct
            setExcellenceJasonData(response.data);
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }

    useEffect(() => {
        fatchExcellence();
    }, []);
    return(
        <div>
            <>
                <section className="choose-area">
                    <div className="container">
                        <div className="section-header">
                            <h1>Choose Phicsart Excellence</h1>
                        </div>
                        <div className="row">
                        {excellenceJasonData.map(item => (
                                // Check if the serial number is odd before rendering                        
                                <div className="col-md-4" key={item.id}>
                                    <div className="choose-card">
                                        <img src={config.FILE_URL+item.thumb} alt="" className="img-fluid" />
                                        <div className="choose-detils">
                                            <h5>{item.title}</h5>
                                            <p>{item.description}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
                <section className="utilize-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 mx-auto animation-area">
                                <div className="animate-box-area">
                                    <div className="adapt">Our Services</div>
                                    <div className="animation-1">
                                        <div className="animat-1-box">
                                            <img src={config.FILE_URL + "images/top-page/menu/bg_icon.svg"} className="ani-img-1" alt="" />
                                            <img src={config.FILE_URL + "images/top-page/menu/cp_icon.svg"} className="ani-img-2" alt="" />
                                        </div>
                                    </div>
                                    <div className="animation-2">
                                        <div className="animat-2-box">
                                            <img src={config.FILE_URL + "images/top-page/menu/shadow_icon.svg"} className="ani-img-1" alt="" /> 
                                            <img src={config.FILE_URL + "images/top-page/menu/ghost_icon.svg"} className="ani-img-2" alt="" />
                                            <img src={config.FILE_URL + "images/top-page/menu/retouch_icon.svg"} className="ani-img-3" alt="" />
                                            <img src={config.FILE_URL + "images/top-page/menu/color_icon.svg"} className="ani-img-4" alt="" />
                                        </div>
                                    </div>
                                    <div className="animation-3">
                                        <div className="animat-3-box">
                                            <img src={config.FILE_URL + "images/top-page/menu/ui_ux.svg"} className="ani-img-2" alt=""/>
                                            <img src={config.FILE_URL + "images/top-page/menu/logo_design.svg"} className="ani-img-3" alt=""/>
                                            <img src={config.FILE_URL + "images/top-page/menu/seo.svg"} className="ani-img-4" alt=""/>
                                            <img src={config.FILE_URL + "images/top-page/menu/smm.svg"} className="ani-img-5" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                
                    </div>
                </section>
            </>
        </div>
        
    )
}
export default ChooseArea;