import React, { useEffect, useState }  from 'react'; 
import axios from 'axios';
import { Link } from 'react-router-dom';
import config from '../../config';
const SmmService = () =>{
    const [smmServiceJsonData, setSmmServiceJsonData] = useState([]); 

    async function fetchSmmServiceFunc() {
        try {
            const responseSmmService = await axios.get(config.API_URL + config.PAGES_URL);
            setSmmServiceJsonData(responseSmmService.data[7].sections[1]);
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }
    useEffect(() => {
        fetchSmmServiceFunc();
    }, []);
    return(
        <>
            <section className="smm-service-area">
                <div className="container">
                    <div className="section-header text-center">
                        <h3>{smmServiceJsonData.title}</h3>
                    </div>
                    <div className="row">
                    {smmServiceJsonData && smmServiceJsonData.items && smmServiceJsonData.items.length > 0 ? (
                        smmServiceJsonData.items.map(smmServiceData => (
                            <div className="col-md-6" key={smmServiceData.id}>
                                <div className="service-card">
                                    <div className="card-image">
                                        <img src={config.FILE_URL + smmServiceData.thumbImage} alt="" className="img-fluid" />
                                    </div>
                                    <div className="service-details">
                                        <h3>{smmServiceData.title}</h3>
                                        <p>{smmServiceData.content}</p>
                                    </div>
                                </div>
                            </div>
                        ))
                    ):( console.log())}
                    </div>
                    <div className="banner-offer mb-5 text-center">
                        <Link to="../contactpage" className="btn-service btn">Contact Us</Link>
                    </div>
                </div>
            </section>
        </>
        
    )
}
export default SmmService;