const config = {
  BASE_URL: process.env.REACT_APP_BASE_URL,
  API_URL: process.env.REACT_APP_API_URL,
  FILE_URL: process.env.REACT_APP_FILE_URL, 

  
  EXCELLENCE_URL: 'excellence.json',
  FAQ_URL: 'faq.json',
  PORTFOLIO_URL: 'phicsart-portfolio.json',
  PORTFOLIO_SLIDER_URL: 'phicsart-portfolio-slider.json',
  SERVICE_URL: 'service.json',
  SHORT_INFO_URL: 'short-info.json',
  SITE_INFO_URL: 'site-info.json',
  SLIDER_URL: 'slider.json',
  PAGES_URL: 'web-pages.json',
  WORKFLOWS_URL: 'workflows.json',
  CAREER_URL: 'career.json',
};

export default config;
