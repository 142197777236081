import React, { useEffect, useState }  from 'react'; 
import axios from 'axios';
import config from '../config';
export default function PrivacyFunc() {
    const [privacyPageJsonData, setPrivacyPageJsonData] = useState([]); 

    async function fetchPrivacyPage() {
        try {
            const responsePrivacy = await axios.get(config.API_URL + config.PAGES_URL); // Make sure the path is correct
            setPrivacyPageJsonData(responsePrivacy.data[3]);
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }
    useEffect(() => {
        fetchPrivacyPage();
    }, []);    
  return (
    <>
        <section className="privacy-terms-area">
            <div className="privacy">
                <div className="container">
                    <div className="section-header">
                        <h3>{privacyPageJsonData.title}</h3>
                        <div dangerouslySetInnerHTML={{ __html: privacyPageJsonData.content }}></div>
                    </div>
                </div>
            </div>        
        </section> 
    </>
   
  )
}
