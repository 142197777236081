import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import { Link, useParams } from 'react-router-dom';
import config from '../../config';
const SubSevice = () =>{
  const { id } = useParams();
  const parsedId = parseInt(id, 10); // Parse id as an integer
  const idMinusOne = parsedId - 1; // Add 1 to the parsed id
  const [subservicJsonData, setSubServiceJsonData] = useState ([]);

    // service array fetch function 
    async function fetchService() {
        try {
        const response = await axios.get(config.API_URL + config.SERVICE_URL);
        setSubServiceJsonData(response.data[idMinusOne].subService);
        } catch (error) {
        console.error('Error reading JSON file:', error);
        }
    }
    // eslint-disable-next-line
    useEffect(() => {
        fetchService();
      }, [id]); // eslint-disable-next-line
    return(
        <>
            <section className="service-details-subitems">
                <div className="container">
                {subservicJsonData.map(item => (
                    item.id % 2 !== 0 ? (
                        <div className="row" key={item.id}>
                            <div className="col-md-6">
                                <div className="compear-slider">
                                    <ReactCompareSlider
                                        itemOne={<ReactCompareSliderImage src={config.FILE_URL+item.beforeImage} srcSet={config.FILE_URL+item.beforeImage} alt="Image one" />}
                                        itemTwo={<ReactCompareSliderImage src={config.FILE_URL+item.afterImage} srcSet={config.FILE_URL+item.afterImage} alt="Image two" />}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="d-flex align-items-center h-100">
                                    <div className="service-page-details">
                                        <h4>{item.title}</h4>
                                        <p dangerouslySetInnerHTML={{ __html: item.content }}></p>                                    
                                        {/* <h5>{item.price}</h5> */}
                                        <Link to="/contactpage" className="btn btn-subitems mt-2">Contact for Price !</Link>
                                    </div>
                                </div>
                            
                            </div>
                        </div> 
                    ):(
                        <div className="row" key={item.id}>
                            <div className="col-md-6">
                                <div className="d-flex align-items-center h-100">
                                    <div className="service-page-details">
                                        <h4>{item.title}</h4>
                                        <p dangerouslySetInnerHTML={{ __html: item.content }}></p>                                    
                                        {/* <h5>{item.price}</h5> */}                                    
                                        <Link to="/contactpage" className="btn btn-subitems mt-2">Contact for Price !</Link>
                                    </div>
                                </div>
                            
                            </div>
                            <div className="col-md-6">
                                <div className="compear-slider">
                                    <ReactCompareSlider
                                        itemOne={<ReactCompareSliderImage src={config.FILE_URL+item.beforeImage} srcSet={config.FILE_URL+item.beforeImage} alt="Image one" />}
                                        itemTwo={<ReactCompareSliderImage src={config.FILE_URL+item.afterImage} srcSet={config.FILE_URL+item.afterImage} alt="Image two" />}
                                    />
                                </div>
                            </div>
                        </div> 
                    )
                            
                    ))} 
                </div>    
            </section>
        </>
        
    )
}
export default SubSevice;