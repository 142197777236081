import React, { useEffect, useState }  from 'react'; 
import axios from 'axios';
import 'swiper/css';
import config from '../../config';
import { Link } from 'react-router-dom';
const SeoBanner = () =>{
    const [seoBannerJsonData, setSeoBannerJsonData] = useState([]);
    async function fetchSmmFunc() {
        try {
            const responseSeoBanner = await axios.get(config.API_URL + config.PAGES_URL);
            console.log('response_about_', responseSeoBanner.data[8]);
            setSeoBannerJsonData(responseSeoBanner.data[8]);
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }
    useEffect(() => {
        fetchSmmFunc();
    }, []);  
    return(
        <>
            <section className="banner-area">
                <div className="banner-body">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="banner-left">
                                    <div className="">
                                        <h1 className="banner-title">{seoBannerJsonData.title}</h1>
                                        <p className="banner-details">{seoBannerJsonData.content}</p>
                                        <div className="banner-offer mb-5">
                                        <Link to="../contactpage" className="banner-trial-btn btn">Place Order <img src="asstes/images/top-page/banner/order_icon.svg" alt="" className="color-icon" /> <img src="asstes/images/top-page/banner/order_icon_white.svg" alt="" className="white-icon" /></Link>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="col-md-7">
                                <div className="text-center">
                                    <img src={config.FILE_URL + seoBannerJsonData.thumbImage} alt="" className="img-fluid" /> 
                                </div>                            
                            </div>
                        </div>
                    </div>   
                </div>
            </section>
        </>
        
    )
}
export default SeoBanner;