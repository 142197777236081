import React, { useEffect, useState }  from 'react';
import { useLocation } from 'react-router-dom';
import { Swiper, SwiperSlide} from 'swiper/react';
import { Autoplay } from 'swiper/modules'
import { Pagination } from 'swiper/modules';
import axios from 'axios';
import 'swiper/css';
import Isotope from './IsotopeDemo';
import config from '../../config';
const PortfolioSlider = () =>{
    const location = useLocation();

    // Scroll to the top of the page when the route changes
    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top
    }, [location]);
    const [sliderJsonData, setSliderJsonData] = useState([]);
        async function fetchSliderData() {
            try {
                const response = await axios.get(config.API_URL + config.PORTFOLIO_SLIDER_URL);
                setSliderJsonData(response.data);
            } catch (error) {
                console.error('Error reading JSON file:', error);
            }
        }
        useEffect(() => {
            fetchSliderData();
          }, []);

    return(
        <> 
            <section className="portfolio-slider-area"> 
                <div className="container">
                    <div className="slider-area">
                        <Swiper
                            spaceBetween={0}
                            slidesPerView={1}
                            speed={500}
                            autoplay={{
                            delay: 2000,
                            disableOnInteraction: false,
                            }}
                            loop={true}
                            modules={[Autoplay, Pagination]}
                            pagination={{
                                clickable: true,
                            }}
                            className="swiper portfolioSwiper"
                            >    
                            {sliderJsonData.map(item => (
                                <SwiperSlide className="swiper-slide" key={item.id}>
                                    <img src={config.FILE_URL + item.thumbImg} alt={item.title} className="img img-fluid"/>
                                </SwiperSlide>
                            ))}                                        
                        </Swiper>
                    </div>
                </div>
            </section> 
        </>
    
    )
}
  const Portfolio = () =>{
    return(
        <div>
            <PortfolioSlider />
            <Isotope />
        </div>
        
    )
}
export default Portfolio;