import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import UiUxBanner from './UiUxBanner';
import UiUxService from './UiUxService';
import UiUxWorkflow from './UiUxWorkflow';
import UiUxOverview from './UiUxOverview';
function ServiceDetails() {
    const location = useLocation();

    // Scroll to the top of the page when the route changes
    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top
    }, [location]);
    return(
        <div>
           <UiUxBanner />
           <UiUxService />
           <UiUxWorkflow />
           <UiUxOverview />
        </div> 
    )
}
export default ServiceDetails;