import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import config from '../../config';

const UiUxOverview = () =>{
    const [expertiseOverviewJasonData, setExpertiseOverviewJasonData] = useState([]);

    async function fetchUiuxPage() {
        try {
            const response = await axios.get(config.API_URL + config.PAGES_URL);    
            // console.log('response_', response.data[5].sections[2].items);
            setExpertiseOverviewJasonData(response.data[5].sections[2]);
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }
    useEffect(() => {
        fetchUiuxPage();
    }, []);
    return(
        <>
            <div>
                <section className="design-expertise-area my-5">
                    <div className="container">
                        <div className="section-header text-center">
                            <h3>{expertiseOverviewJasonData?.title}</h3>
                        </div>
                        <div className="row mx-auto">
                        {expertiseOverviewJasonData && expertiseOverviewJasonData.items && expertiseOverviewJasonData.items.length > 0 ? (
                        expertiseOverviewJasonData.items.map(overviewItem => (
                            <div className="col" key={overviewItem.id}>
                                <div className="expertise-card text-center">
                                    <img src={config.FILE_URL + overviewItem.thumbImage} alt="" className="img-fluid" />
                                    <h6 className="py-4">{overviewItem.title}</h6>
                                </div>
                            </div>
                        ))
                        ):(console.log())
                        }
                        </div>
                        <div className="banner-offer mb-5 text-center">
                            <Link to="../contactpage" className="btn-service btn">Contact Us</Link>
                        </div>
                    </div>
                </section>
                <section className="utilize-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 mx-auto animation-area">
                                <div className="animate-box-area">
                                    <div className="adapt" style={{textTransform: 'capitalize'}}>
                                            Common Tools we Used !
                                    </div>
                                    <div className="animation-1">
                                        <div className="animat-1-box">
                                            <img src={config.FILE_URL + "images/uiux/figma.png"} className="ani-img-1" alt="" />
                                            <img src={config.FILE_URL + "images/uiux/figma.png"} className="ani-img-2" alt="" />
                                        </div>
                                    </div>
                                    <div className="animation-2">
                                        <div className="animat-2-box">
                                            <img src={config.FILE_URL + "images/uiux/skatch.png"} className="ani-img-1" alt="" />
                                            <img src={config.FILE_URL + "images/uiux/skatch.png"} className="ani-img-2" alt="" />
                                        </div>
                                    </div>
                                    <div className="animation-3">
                                        <div className="animat-3-box">
                                            <img src={config.FILE_URL + "images/uiux/ai.png"} className="ani-img-1" alt=""/>
                                            <img src={config.FILE_URL + "images/uiux/ps.png"} className="ani-img-2" alt=""/>
                                            <img src={config.FILE_URL + "images/uiux/ai.png"} className="ani-img-3" alt=""/>
                                            <img src={config.FILE_URL + "images/uiux/ai.png"} className="ani-img-4" alt=""/>
                                            <img src={config.FILE_URL + "images/uiux/ps.png"} className="ani-img-5" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                
                    </div>
                </section>
            </div> 
        </>       

    )
}
export default UiUxOverview;