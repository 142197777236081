import axios from 'axios';
import React, { useEffect, useState } from 'react';
import config from '../../config';
const WorkflowArea = () =>{ 
    const [workflowJasonData, setWordflowsJasonData] = useState([]);

    async function fetchWorkflow() {
        try {
            const response = await axios.get(config.API_URL + config.WORKFLOWS_URL);
            setWordflowsJasonData(response.data[0]);
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }
    useEffect(() => {
        fetchWorkflow();
    }, []);
    return(
        <>
            <section className="efficient-area">
                <div className="container">
                    <div className="section-header">
                        <h1>{workflowJasonData.title}</h1>
                    </div>
                    <div className="row">
                
                    <div className="col-lg-4 col-md-4 d-sm-block d-none ">
                    {workflowJasonData?.steps ? (
                            workflowJasonData.steps.map(item => (
                                item.serial % 2 !== 0 ? (
                                <div className="efficient-titel" key={item.id}>
                                    <p><span>{item.serial}</span></p> 
                                    <p><strong>{item.title}</strong>{item.description}</p>  
                                </div>
                                ) : null
                            ))
                            ) : (console.log())}
                        </div>
                        
                        <div className="col-lg-4 col-md-4">
                            <img src={config.FILE_URL+workflowJasonData.thumbImg} alt="" className="img-fluid"/>
                        </div>
                        <div className="col-lg-4 col-md-4 d-sm-block d-none">
                        {workflowJasonData && workflowJasonData.steps && workflowJasonData.steps.length > 0 ? (
                            workflowJasonData.steps.map(item => (
                                item.serial % 2 === 0 ? (
                                <div className="efficient-titel2" key={item.id}>
                                    <p><span>{item.serial}</span></p> 
                                    <p><strong>{item.title}</strong>{item.description}</p>  
                                </div>
                                ) : null
                            ))
                            ) : ( console.log() )}
                        </div>
                        <div className="col-sm-12 d-sm-none d-block">
                        {workflowJasonData && workflowJasonData.steps && workflowJasonData.steps.length > 0 ? (
                        workflowJasonData.steps.map(item => (
                            <div className="efficient-titel" key={item.id}>
                                <p><span>{item.serial}</span></p> 
                                <p><strong>{item.title}</strong>{item.description}</p>  
                            </div>
                        ))
                        ):( console.log())}
                        </div>
                    </div>
                </div>
            </section> 
        </>
        
    )
}
export default WorkflowArea;