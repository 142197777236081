import React, { useEffect, useState }  from 'react';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import axios from 'axios';
import { Link } from 'react-router-dom'; 
import config from '../../config';

const MainService = () =>{
    const [servicJsonData, setServiceJsonData] = useState ([]);

    // service array fetch function 
    async function fetchService() {
        try {
        const response = await axios.get(config.API_URL + config.SERVICE_URL);
        setServiceJsonData(response.data);
        } catch (error) {
        console.error('Error reading JSON file:', error);
        }
    }
    useEffect(() => {
        fetchService();
      }, []);
    return(
        <>
            <section className="service-page-area">
                <div className="container">
                    <div className="section-header text-center my-5">
                        <h3>Professional phics Art Services</h3>
                    </div>
                    {servicJsonData.map(item => (
                        item.id % 2 !== 0 ? (
                            <div className="row" key={item.id}> 
                                <div className="col-md-6" >
                                    <div className="compear-slider">
                                        <ReactCompareSlider
                                            itemOne={<ReactCompareSliderImage src={config.FILE_URL+item.beforeImage} srcSet={config.FILE_URL+item.beforeImage} alt="Image one" />}
                                            itemTwo={<ReactCompareSliderImage src={config.FILE_URL+item.afterImage} srcSet={config.FILE_URL+item.afterImage} alt="Image two" />}
                                        />
                                    </div>
                                </div>   
                                <div className="col-md-6">
                                    <div className="service-page-details">
                                        <h4>{item.title}</h4>
                                        <p dangerouslySetInnerHTML={{ __html: item.content }}></p>
                                        {/* <ul>
                                            <li><i className="fa-solid fa-circle-check"></i> Professional-Grade Precision</li>
                                            <li><i className="fa-solid fa-circle-check"></i> Clean and Polished Images</li>
                                            <li><i className="fa-solid fa-circle-check"></i> Consistent Brand Presence</li>
                                            <li><i className="fa-solid fa-circle-check"></i> Subject Emphasis</li>
                                            <li><i className="fa-solid fa-circle-check"></i> Elevated Visual Appeal</li>
                                        </ul>
                                        <p>Enhance the visual appeal of your images, create a consistent brand presence, and make your subjects stand out with our flawless background removal service. Trust us to deliver exceptional results that elevate your visuals to a whole new level.</p> */}
                                        <Link to={`/service_details/${item.id}`}> Details </Link>
                                    </div>
                                </div>  
                            </div>
                            ) : (
                                <div className="row" key={item.id}  > 
                                    <div className="col-md-6">
                                        <div className="service-page-details">
                                            <h4>{item.title}</h4>
                                            <p dangerouslySetInnerHTML={{ __html: item.content }}></p>
                                            {/* <ul>
                                                <li><i className="fa-solid fa-circle-check"></i> Professional-Grade Precision</li>
                                                <li><i className="fa-solid fa-circle-check"></i> Clean and Polished Images</li>
                                                <li><i className="fa-solid fa-circle-check"></i> Consistent Brand Presence</li>
                                                <li><i className="fa-solid fa-circle-check"></i> Subject Emphasis</li>
                                                <li><i className="fa-solid fa-circle-check"></i> Elevated Visual Appeal</li>
                                            </ul>
                                            <p>Enhance the visual appeal of your images, create a consistent brand presence, and make your subjects stand out with our flawless background removal service. Trust us to deliver exceptional results that elevate your visuals to a whole new level.</p> */}
                                            <Link to={`/service_details/${item.id}`}> Details </Link>
                                        </div>
                                    </div>
                                    <div className="col-md-6" >
                                        <div className="compear-slider">
                                            <ReactCompareSlider
                                                itemOne={<ReactCompareSliderImage src={config.FILE_URL+item.beforeImage} srcSet={config.FILE_URL+item.beforeImage} alt="Image one" />}
                                                itemTwo={<ReactCompareSliderImage src={config.FILE_URL+item.afterImage} srcSet={config.FILE_URL+item.afterImage} alt="Image two" />}
                                            />
                                        </div>
                                    </div>  
                                </div>
                            )
                        ))} 
                </div>    
            </section>  
        </>
        
    )
}
export default MainService;