import React, { useEffect, useState }  from 'react';
import axios from 'axios';
import {SlideshowLightbox} from 'lightbox.js-react'
import 'lightbox.js-react/dist/index.css'
import config from '../../config';
import { Link } from 'react-router-dom';
const UiUxService = () =>{
    const [uiuxServiceJasonData, setUiuxServiceJasonData] = useState([]);

    async function fetchUiuxService() {
        try {
            const response = await axios.get(config.API_URL + config.PAGES_URL);    
            // console.log('response_', response.data[5].sections[0]);
            setUiuxServiceJasonData(response.data[5].sections[0]);
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }
    useEffect(() => {
        fetchUiuxService();
    }, []);

    return(
        <>
            <section className="ui-portfolio-area">
                <div className="container">
                    <div className="section-header text-center">
                        <h1>{uiuxServiceJasonData.title}</h1>
                        <p className="w-60">{uiuxServiceJasonData.content}</p>
                    </div>
                    <div className="service-bg">
                        <div className="row m-0 p-2">
                        {uiuxServiceJasonData && uiuxServiceJasonData.items && uiuxServiceJasonData.items.length > 0 ? (
                            uiuxServiceJasonData.items.map(servicePortfolioItem => (
                                <div className="col-lg-4 col-md-6" key={servicePortfolioItem.id}>
                                <div className="service-card">
                                    <div className="portfolio-image">
                                        <div className="content-overlay"></div>
                                        <SlideshowLightbox className="container grid grid-cols-3 gap-2 mx-auto">
                                            <img className="content-image" src={config.FILE_URL + servicePortfolioItem.thumbImage} alt="" />
                                        </SlideshowLightbox>
                                        <div className="content-details fadeIn-bottom">
                                            <h5 className="content-title">{servicePortfolioItem.title}</h5>
                                            <SlideshowLightbox className="container grid grid-cols-3 gap-2 mx-auto">
                                                <img className="content-image" src={config.FILE_URL + servicePortfolioItem.thumbImage} alt="" style={{height: "50px", width:"50px"}}/>
                                            </SlideshowLightbox>
                                        </div>                                    
                                    </div>
                                </div>
                            </div>
                            ))
                        ):null}
                        </div>
                        <div className="text-center">
                            <Link to="https://dribbble.com/phicsart27" className="btn btn-service" target="_blank" rel="noopener noreferrer">See More on Dribble  <i className="fa-brands fa-dribbble"></i></Link>
                        </div>
                    </div>            
                </div>    
            </section> 
        </>
        
    )
}
export default UiUxService;