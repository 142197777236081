import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import config from '../config';
function Header(){ 
    const [siteInfoJsonData, setSiteInfoJsonData] = useState([]); 
    const [servicJsonData, setServiceJsonData] = useState ([]);

    async function fetchService() {
        try {
            const response = await axios.get(config.API_URL + config.SERVICE_URL);
            setServiceJsonData(response.data);
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }
    async function fetchSiteInfo() {
        try {
            const response = await axios.get(config.API_URL + config.SITE_INFO_URL);
            setSiteInfoJsonData(response.data);
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }
    useEffect(() => {
        fetchService();
        fetchSiteInfo();
    }, []);
    return(
        <>
            <header className="header-menu" id="header">
                <div className="container header-container">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="logo-menu">
                            <div className="logo">
                                <Link  to={config.BASE_URL}>
                                        <img src={config.FILE_URL + siteInfoJsonData.logo} alt="" className="img-fluid" />
                                    <h6> &nbsp; {siteInfoJsonData.name}</h6>
                                </Link>                          
                            </div>
                            <div className="main-menu">
                                <ul className="menu-unorder-list">
                                    <div className="mobile-close d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center">
                                            <img src={config.FILE_URL + "images/top-page/menu/logo.svg"} alt="" /> <h6> &nbsp; Phicsart</h6>
                                        </div>
                                        <div className="close-icon">
                                            <i className="fa-solid fa-circle-xmark"></i>
                                        </div>
                                    </div>
                                    <li className="list dropdown-list">
                                        <Link to="./servicepage">  Service </Link> <i className="fa-solid fa-angle-down"></i>
                                        <div className="dropdown">
                                            <div className="dropdown-unorder">
                                                <ul className="drop-list-1">
                                                {servicJsonData.map(item => (
                                                        item.id <= 3 ? (
                                                            <li key={item.id}>
                                                                <Link to={`/service_details/${item.id}`}>    
                                                                <img src={config.FILE_URL + item.icon} alt="" className="bg-icon" />
                                                                    <div className="d-block ps-1">
                                                                        {item.title}
                                                                        <span>{item.subtitle}</span>
                                                                    </div>                                                            
                                                                </Link>
                                                            </li>
                                                            ) : null
                                                        ))} 
                                                </ul>
                                                <ul className="drop-list-2">
                                                    {servicJsonData.map(item => (
                                                        item.id > 3 ? (
                                                            <li key={item.id}>
                                                                <Link to={`/service_details/${item.id}`}>    
                                                                    <img src={config.FILE_URL + item.icon} alt="" className="bg-icon" />
                                                                    <div className="d-block ps-1">
                                                                        {item.title}
                                                                        <span>{item.subtitle}</span>
                                                                    </div>                                                            
                                                                </Link>
                                                            </li>
                                                            ) : null
                                                        ))}
                                                </ul>
                                            </div>
                                            <div className="text-center">
                                                <Link to="/portfolio" className="btn btn-portfolio">Portfolio</Link>
                                            </div>
                                        </div>                                
                                    </li>
                                    <li className="list dropdown-list">
                                        <a href="#0"> Branding & Marketing Solutions </a> <i className="fa-solid fa-angle-down"></i>
                                            <div className="dropdown">
                                                <div className="dropdown-unorder">
                                                    <ul className="drop-list-1">
                                                        <li>
                                                            <Link to="/uiux">    
                                                                <img src={config.FILE_URL+"images/top-page/menu/ui_ux.svg"} alt="" className="bg-icon" />
                                                                <div className="d-block ps-1">
                                                                    UI / UX (Web & Mobile)
                                                                    <span> Intuitive Interfaces, Seamless Experiences</span>
                                                                </div>
                                                                
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/logo">    
                                                                <img src={config.FILE_URL+"images/top-page/menu/logo_design.svg"} alt="" className="cp-icon" />
                                                                <div className="d-block ps-1">
                                                                    Logo Design & Branding
                                                                <span> Memorable Logos, Strong Brand Identity</span>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                    <ul className="drop-list-2">
                                                        <li>
                                                            <Link to="/smm">    
                                                                <img src={config.FILE_URL+"images/top-page/menu/smm.svg"} alt="" className="cp-icon" />
                                                                <div className="d-block ps-1">
                                                                    Social Media Marketing (SMM)
                                                                <span>Engage, Grow, Rank, Connect on Platforms</span>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/seo">    
                                                                <img src={config.FILE_URL+"images/top-page/menu/seo.svg"} alt="" className="retouch-icon" />
                                                                <div className="d-block ps-1">
                                                                    Search Engine Optimization (SEO)
                                                                    <span>Boost Online Visibility, Improve Rankings</span>
                                                                </div>                                                
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div> 
                                    </li>
                                    <li className="list mobile-list">
                                        <Link to="./contactpage">Get Quote</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="menu-overlay"></div>
                        <div className="user-menu">
                            <div className="viewer-links">
                                <Link to="./contactpage" className="quote-btn me-3">Get Quote</Link>
                                {/* <a href="#0" className="fill-trial-btn"> Free Trial</a>  */}
                            </div>
                            {/* <div className="login-links d-flex align-items-center">
                                <a href="#0" className="client-btn"> <img src="asstes/images/top-page/menu/user-photo.png" alt="" className="user-photo"> <span>First Name</span> </a />
                                <div className="log-out"><a href="#0" className="log-out-btn"> Logout </a></div> 
                            </div>  */}
                            {/* <div className="user-icon"><a href="login.html" className="user-btn"  data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Login / Register"><img src="asstes/images/top-page/menu/user.svg" alt="" className="img-fluid" /></a></div> */}
                            <div className="mobile-bar-icon">
                                <span className="bar-icon"><i className="fa-solid fa-bars-staggered"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
      
    ) 
}
export default Header;