import React, { useEffect, useState }  from 'react';
import { Swiper, SwiperSlide} from 'swiper/react';
// import required modules
import { Autoplay } from 'swiper/modules'
import 'swiper/css';
import axios from 'axios';
import { Link } from 'react-router-dom'; 
import config from '../../config';
const TopBanner = () =>{
    const [siteInfoJsonData, setSiteInfoJsonData] = useState([]);
    const [sliderJsonData, setSliderJsonData] = useState([]);

    async function fetchSliderData() {
        try {
            const response = await axios.get(config.API_URL + config.SLIDER_URL);
            setSliderJsonData(response.data);
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }// siteinfo function 
    async function fetchSiteInfo() {
        try {
            const response = await axios.get(config.API_URL + config.SITE_INFO_URL);
            setSiteInfoJsonData(response.data);
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    } 
    useEffect(() => {
        fetchSiteInfo();   
        fetchSliderData();
      }, []);
    return(
        <>
            <section className="banner-area">
                <div className="banner-body">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="banner-left">
                                    <div className="">
                                        <h1 className="banner-title">{siteInfoJsonData.descriptionTitle}</h1>
                                        <p className="banner-details">{siteInfoJsonData.description}</p>
                                        <div className="banner-offer mb-5">
                                            <Link to="contactpage" className="banner-trial-btn btn">
                                                Place Order 
                                                <img src={config.FILE_URL + 'images/top-page/banner/order_icon.svg'} alt="" className="color-icon" /> 
                                                <img src={config.FILE_URL + 'images/top-page/banner/order_icon_white.svg'} alt="" className="white-icon" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="col-md-7">
                                <div className="banner-right">
                                    <div className="slider-area">
                                        <Swiper
                                        spaceBetween={0}
                                        slidesPerView={1}
                                        speed={500}
                                        autoplay={{
                                        delay: 2000,
                                        disableOnInteraction: false,
                                        }}
                                        loop={true}
                                        modules={[Autoplay]}
                                        className="swiper mySwiper"
                                        >    
                                        {sliderJsonData.map(item => (
                                            <SwiperSlide className="swiper-slide" key={item.id}>
                                                <img src={config.FILE_URL + item.thumb} alt={item.title} className="img img-fluid"/>
                                            </SwiperSlide>
                                        ))}                                        
                                        </Swiper>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>   
                </div>
            </section>
        </>
    )
}
export default TopBanner;