import React, { useEffect, useState }  from 'react'; 
import axios from 'axios';
import 'swiper/css';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide} from 'swiper/react';
import { Autoplay } from 'swiper/modules'
import config from '../../config';
const LogoBanner = () =>{
    const [logoPageJsonData, setLogoPageJsonData] = useState([]); 

    async function fetchLogoPageFunc() {
        try {
            const responseLogoPage = await axios.get(config.API_URL + config.PAGES_URL);
            setLogoPageJsonData(responseLogoPage.data[6]);
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }
    useEffect(() => {
        fetchLogoPageFunc();
    }, []);    
    return(
        <>
            <section className="banner-area logo-banner">
            <div className="banner-body">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="banner-left">
                                <div className="">
                                    <h1 className="banner-title">{logoPageJsonData.title}</h1>
                                    <p className="banner-details">{logoPageJsonData.content}</p>
                                    <div className="banner-offer mb-5">
                                        <Link to="../contactpage" className="banner-trial-btn btn">
                                            Place Order 
                                            <img src={config.FILE_URL + "images/top-page/banner/order_icon.svg"} alt="" className="color-icon" /> 
                                            <img src={config.FILE_URL + "images/top-page/banner/order_icon_white.svg"} alt="" className="white-icon" />
                                        </Link>
                                    </div>
                                </div>
                            </div>                            
                        </div>
                        <div className="col-md-7">
                            <div className="banner-right">                                
                                <div className="slider-area">
                                <Swiper
                                    spaceBetween={0}
                                    slidesPerView={1}
                                    speed={500}
                                    autoplay={{ delay: 2000, disableOnInteraction: false }}
                                    loop={true}
                                    modules={[Autoplay]}
                                    className="swiper mySwiper"
                                >    
                                    {logoPageJsonData && logoPageJsonData.sliderImages && logoPageJsonData.sliderImages.length > 0 ? (
                                        logoPageJsonData.sliderImages.map(logoSliderData => (
                                            <SwiperSlide className="swiper-slide" key={logoSliderData.id}>
                                            <img src={config.FILE_URL + logoSliderData.Image} alt={logoSliderData.title} className="img img-fluid"/>
                                        </SwiperSlide>                                        
                                        ))
                                    ):(console.log())}                                      
                                </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>   
            </div>
            </section>
        </>
        
        
    )
}
export default LogoBanner;