import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TopBanner from './TopBanner';
import ShortAbout from './ShortAbout';
import ServiceArea from './ServiceArea';
import WorkflowArea from './WorkflowArea';
import ChooseArea from './ChooseArea';

// import FaqArea from './FaqArea'
function HomePage() {
    const location = useLocation();

  // Scroll to the top of the page when the route changes
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top
  }, [location]);
    return(
        <div>
            <TopBanner />
            <ShortAbout />
            <ServiceArea />
            <WorkflowArea />
            <ChooseArea />
            {/* <FaqArea /> */}
        </div>
    )
}
export default HomePage;