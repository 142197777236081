import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import About from './Components/About';
import ContactPage from './Components/ContactPage/ContactPage';
import Footer from './Components/Footer';
import Header from './Components/Header';
import Logo from './Components/Logo/Logo';
import NotFound from './Components/NoPage';
import Portfolio from './Components/Portfolio/Portfolio';
import Privacy from './Components/Privacy';
import Seo from './Components/SEO/Seo';
import Smm from './Components/SMM/Smm';
import ServiceDetailsPage from './Components/ServiceDetails/ServiceDetails';
import ServicePage from './Components/ServicePage/ServicePage';
import Terms from './Components/Terms';
import HomePage from './Components/TopPage/HomePage';
import UiUx from './Components/UiUx/UiUx';
import Career from './Components/Career/Career';
import CareerDetails from './Components/Career/CareerDetails';
function App() {

  return (
   <div>
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} exact />
        <Route path="/servicepage" element={<ServicePage />} />
        <Route path="/about" element={<About />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/contactpage" element={<ContactPage />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/uiux" element={<UiUx />} />
        <Route path="/logo" element={<Logo />} />
        <Route path="/smm" element={<Smm />} />
        <Route path="/seo" element={<Seo />} />
        <Route path="/career" element={<Career />} />
        <Route path="/career_details/:id" element={<CareerDetails />} />
        <Route path="/service_details/:id" element={<ServiceDetailsPage />} />
        <Route path="*" element={<NotFound />} />
        {/* <Route path="/page3" exact component={Page3} /> */}
        {/* Add more routes for your other pages */}
      </Routes>
      <Footer />
  </Router>
   </div>
  );
}

export default App;
