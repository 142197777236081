import React, { useEffect, useState }  from 'react'; 
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';
function ContactPage() {
    const location = useLocation();

    // Scroll to the top of the page when the route changes
    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top
    }, [location]);
    const [contactPageJsonData, setContactPageJsonData] = useState([]); 
    const [showMap] = useState(true); // You can set this state based on your conditions

    async function fetchContactPage() {

        try {
            const responseContact = await axios.get(config.API_URL + config.PAGES_URL);
            // console.log('response__', responseContact.data[0]);
            setContactPageJsonData(responseContact.data[0]);
        } catch (error) {
            // console.log(setShowMap)
            console.error('Error reading JSON file:', error);
        }
    }
    useEffect(() => {
        fetchContactPage();
    }, []);     
    return(
        <div>
            <section className="my-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <img src={config.FILE_URL + contactPageJsonData.thumbImage} alt="" className="img-fluid" />
                        </div>
                        <div className="col-md-6">
                            <div className="contact-area">
                                <div className="section-header">
                                    <h3>{contactPageJsonData.title}</h3>
                                    <p>{contactPageJsonData.content}</p>
                                </div>
                                <div className="contact-form">
                                    <form action="">
                                        <div className="mb-3">
                                            <input type="text" className="form-control" name="" placeholder="Name" />
                                        </div>
                                        <div className="mb-3">
                                            <input type="email" className="form-control" name="" placeholder="email@example.com" />
                                        </div>
                                        <div className="mb-3">
                                            <input type="text" className="form-control" name="" placeholder="Organization " />
                                        </div>
                                        <div className="mb-3">
                                            <input type="text" className="form-control" name="" placeholder="Phone " />
                                        </div>
                                        <div className="mb-3">
                                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Description"></textarea>
                                        </div>
                                        <button className="btn btn-submit" type="submit">Send</button>
                                    </form>
                                    
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </section>
            <div className="container my-5">
                <div className="row">
                {showMap && (
            <iframe
                title="my-iframe-title"
                src={contactPageJsonData.googleMapUrl}
                width="100%"
                height="650"
                style={{ border: '0' }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
            )}
            </div>
            </div>
        </div> 
    )
}
export default ContactPage;