import React, { useEffect, useState }  from 'react';
// import axios from 'axios';
//import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';
const ServiceDetailsBanner = () =>{
    // Use useParams() to access the URL parameters
    const { id } = useParams();
    const parsedId = parseInt(id, 10);
    const idMinusOne = parsedId - 1;
    const [servicJsonData, setServiceJsonData] = useState([]);

    async function fetchService() {
      try {
        const response = await axios.get(config.API_URL + config.SERVICE_URL);
        //console.log('service_details_url' + response.data);
        setServiceJsonData(response.data[idMinusOne]);
      } catch (error) {        
        console.error('Error reading JSON file:', error);
      }
    }    
    useEffect(() => {
        fetchService();
      }, [id]);
  
    return(
        <>
            <section className="service-details-banner-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="banner-content">
                                    <div className="section-header">
                                        <h3>{servicJsonData.title}</h3>
                                        <p dangerouslySetInnerHTML={{ __html: servicJsonData.content }}></p>
                                    </div>
                                    <div className="service-offer d-flex justify-content-between">
                                        <div className="d-flex">
                                            <img src={config.FILE_URL+"images/service_details/save-cost.png"}alt="" />
                                            <p>Save Cost</p>
                                        </div>
                                        <div className="d-flex">
                                            <img src={config.FILE_URL+"images/service_details/support.png"}alt="" />
                                            <p>24/7 Support</p>
                                        </div>
                                        <div className="d-flex">
                                            <img src={config.FILE_URL+"images/service_details/professional.png"} alt="" />
                                            <p>30+  Professionalt</p>
                                        </div>
                                    </div>
                                    <div className="service-order">
                                        <a href="/contactpage" className="btn btn-service">Get Quote</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="service-image">
                                    <img src={config.FILE_URL + servicJsonData.thumb} alt="" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>    
            </section> 
        </>
        
    )
}
export default ServiceDetailsBanner;