import React, { useEffect, useState }  from 'react'; 
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
export default function About() {
    const location = useLocation();

    // Scroll to the top of the page when the route changes
    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top
    }, [location]);
    const [aboutPageJsonData, setAboutPageJsonData] = useState([]); 
    async function fetchSiteInfo() {
        try {
            const responseAbout = await axios.get(config.API_URL + config.PAGES_URL);
            setAboutPageJsonData(responseAbout.data[1]);
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }
    useEffect(() => {
        fetchSiteInfo();
    }, []);
  return (
    <>
        <div>
            <section className="about-page-banner-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="section-header">
                                <h3>{aboutPageJsonData.title}</h3>                            
                                <div dangerouslySetInnerHTML={{ __html: aboutPageJsonData.content }}></div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="about-page-image">
                                <img src={config.FILE_URL + aboutPageJsonData.thumbImage} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            {/* about page section our story  */}
            {aboutPageJsonData && aboutPageJsonData.sections && aboutPageJsonData.sections.length > 0 ? (
                    <section className="short-about-area2">
                    <div className="container">
                        <div className="section-header">
                            <h1>{aboutPageJsonData?.sections[0]?.sectionTitle}</h1>
                            <p className="text-center">{aboutPageJsonData?.sections[0]?.sectionContent}</p>
                        </div>
                    </div>
                </section>
            ):( console.log())}

            {aboutPageJsonData && aboutPageJsonData.sections && aboutPageJsonData.sections.length > 0 ? (
                <section className="strength-area">
                    <div className="container">
                        <div className="section-header text-center">
                            <h3>{aboutPageJsonData?.sections[1]?.sectionTitle}</h3>
                        </div>
                        <div className="row">
                        {aboutPageJsonData.sections[1].sectionItems.map(sectionItemsData => (
                            <div className="col-md-6 about-service-box">
                                <div className="d-flex">
                                    <div className="img mt-3">
                                        <img src={config.FILE_URL + sectionItemsData.thumbImage} alt="" />
                                    </div>
                                    <div className="strength-header d-flex align-items-center">
                                        <div>
                                            <h5 className="mt-3">{sectionItemsData.title}</h5>
                                            <p>{sectionItemsData.content}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            ))
                        }
                        </div>
                    </div>
                </section>
            ):( console.log())}


            {/* about page section our story  */}
            {aboutPageJsonData && aboutPageJsonData.sections && aboutPageJsonData.sections.length > 0 ? (
                <section className="process-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 ">
                                <div className="d-flex align-items-center h-100">
                                    <div className="section-header">
                                        <h3>{aboutPageJsonData?.sections[2]?.sectionTitle}</h3>
                                        <p>{aboutPageJsonData?.sections[2]?.sectionContent}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <img src={config.FILE_URL + aboutPageJsonData?.sections[2]?.sectionImage} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </section>
            ):( console.log())}
        </div>
    </>
  )
}
