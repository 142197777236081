import React, { useEffect, useState }  from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'; 
import config from '../../config';
const Career = () =>{
  const [careerJsonData, setCareerJsonData] = useState([]);

  // Career array fetch function
  async function fetchCareer() {
    try {
      const response = await axios.get(config.API_URL + config.CAREER_URL);
      setCareerJsonData(response.data);
    } catch (error) {
      console.error('Error reading JSON file:', error);
    }
  }
  
  useEffect(() => {
    fetchCareer();
  }, []);
  
  // Use another useEffect to log the data when it changes
  return (
    <div>
       <section className="career-area">
        <div className="container">
            <div className="section-header mb-5">
                <h3 className="text-center">Join With PhicsArt</h3>
                <p className="text-center">At PicsArt, we believe in the power of creativity. We're on a mission to empower creators and make the world more expressive through our innovative photo editing and design tools. If you're passionate about tech, design, and pushing the boundaries of creativity, you've come to the right place. Explore exciting career opportunities at PicsArt and be a part of something extraordinary.</p>
            </div>
            <div className="career-card-area">
            {careerJsonData.length > 0 ? (
              careerJsonData.map((career) => (
                <div className="career" key={career.id}>
                    <div className="row">
                        <div className="col-md-10">
                            <div className="career-card">
                                <h3>{career.title}</h3>
                                <p>{career.short_description }</p>
                                <div className="d-flex flex-wrap career-address">
                                <p> <i className="fa-solid fa-location-dot"></i> {career.job_location} </p>
                                <p> <i className="fa-solid fa-briefcase"></i> {career.experience}</p>
                                <p> <i className="fa-solid fa-bangladeshi-taka-sign"></i> {career.salary}</p>
                                <p> <i className="fa-solid fa-clock"></i>{career.deadline} </p> 
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="career-details h-100 d-flex justify-content-between align-items-center w-100">
                                <Link to={`/career_details/${career.id}`} className="btn btn-career">See Details <img src="asstes/images/career/line-purple.png" alt="" className="color-icon" /> <img src="asstes/images/career/line-white.png" alt="" className="white-icon" /></Link>
                            </div>
                        </div>
                    </div>
                </div>
              ))
            ):( null)}       
            </div>
        </div>
      </section>
    </div>
  )
}

export default Career;
