import React, { useEffect, useState }  from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';
const ServiceDetailsPortfolio = () =>{
    const { id } = useParams();
    const parsedId = parseInt(id, 10); // Parse id as an integer
    const idMinusOne = parsedId - 1; // Add 1 to the parsed id
    const [subservicPortJsonData, setSubServicePortJsonData] = useState ([]);

      // service array fetch function 
      async function fetchService() {
          try {
          const response = await axios.get(config.API_URL + config.SERVICE_URL);
          setSubServicePortJsonData(response.data[idMinusOne].defaultPortfolio);
          } catch (error) {
          console.error('Error reading JSON file:', error);
          }
      }
      useEffect(() => {
          fetchService();
        }, [id]);
  return (
    <>
        <section className="service-details-banner">
            <div className="container">
                {subservicPortJsonData.map(item => (
                    <div className="row" key={item.id}>
                        
                        <div className="col-md-6">
                            <img src={config.FILE_URL + item.thumbImg} alt="" className="img-fluid" />
                        </div>
                        <div className="col-md-6">
                            <div className="details-banner-header">
                                <h4>{item.title}</h4>
                                <p dangerouslySetInnerHTML={{ __html: item.content }}></p>
                            </div>
                        </div>
                    </div>
                ))} 
            </div>
        </section>
    </>
   
    
  )
}

export default ServiceDetailsPortfolio;