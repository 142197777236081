import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import LogoBanner from './LogoBanner';
import LogoService from './LogoService';
import LogoChoose from './LogoChoose';
function Logo() {
    const location = useLocation();

    // Scroll to the top of the page when the route changes
    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top
    }, [location]);
    return(
        <div>
           <LogoBanner />
           <LogoService />
           <LogoChoose />
        </div> 
    )
}
export default Logo;