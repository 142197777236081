import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SmmBanner from './SmmBanner';
import SmmService from './SmmService';
import SmmFeatured from './SmmFeatured';
function Smm() {
    const location = useLocation();

    // Scroll to the top of the page when the route changes
    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top
    }, [location]);
    return(
        <div>
           <SmmBanner />
           <SmmFeatured />
           <SmmService />
        </div> 
    )
}
export default Smm;