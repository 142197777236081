import React, { useEffect, useState }  from 'react'; 
import axios from 'axios';
import config from '../config';
export default function TermsPageFunc() {
    const [termsPageJsonData, setTermsPageJsonData] = useState([]); 

    async function fetchPrivacyPage() {
        try {
            const responseTerms = await axios.get(config.API_URL + config.PAGES_URL); // Make sure the path is correct
            setTermsPageJsonData(responseTerms.data[2]);
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }
    useEffect(() => {
        fetchPrivacyPage();
    }, []);     
  return (
    <>
        <section className="privacy-terms-area">
            <div className="privacy">
                <div className="container">
                    <div className="section-header">
                        <h3>{termsPageJsonData.title}</h3>
                        <div dangerouslySetInnerHTML={{ __html: termsPageJsonData.content }}></div>                    
                    </div>
                </div>
            </div>
        </section> 
    </>
    
  )
}
