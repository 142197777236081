import React from 'react';

const FaqService = () =>{
    return(
      <section className="faq-area">
      <div className="container">
          <div className="section-header">
             <h1>Frequently Asked Questions (FAQ)</h1> 
          </div>
          <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      What factors determine the pricing of your services?
                  </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                      Factors such as project complexity, scope of work, turnaround time, customization, and volume impact our pricing. We ensure fair rates that reflect the value and quality of our services.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      How do you ensure the confidentiality and security of our files and sensitive information?
                  </button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                      We prioritize confidentiality and security. Your files and sensitive information are protected through strict measures, secure file transfer, data protection practices, limited access, and secure deletion upon request.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      How do you handle tight deadlines or urgent projects?
                  </button>
                </h2>
                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                      We excel in handling tight deadlines and urgent projects through dedicated resources, efficient workflow, clear communication, prioritization, flexibility, and unwavering commitment to quality.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFour">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                      If we have a large quantity of images for editing, does Phicsart offer the discounts?
                  </button>
                </h2>
                <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                      Yes, we offer discounts for larger quantities of images. We understand the importance of cost-effectiveness for our clients with bulk editing needs. Our pricing structure takes into account the volume and frequency of your image editing requirements. We provide competitive pricing and tailored discount packages to ensure that our services are affordable and beneficial for clients with a substantial image workload. Rest assured, we strive to deliver exceptional value while maintaining the highest quality standards in our work. Contact us to discuss your specific image editing needs, and we'll be happy to provide you with more information about our discounted pricing options for bulk projects.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFive">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                      Can I request revisions or modifications to the delivered work?
                  </button>
                </h2>
                <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                      Yes, we value customer satisfaction and strive to meet your specific requirements. If you need revisions or modifications to the delivered work, we are more than happy to accommodate your requests. We understand that each client may have unique preferences or specific changes they would like to make. Our goal is to ensure that you are completely satisfied with the final results. Simply communicate your desired revisions to us, and we will work closely with you to make the necessary adjustments. Our team is dedicated to delivering high-quality work that meets your expectations, and we are committed to providing excellent customer service throughout the process.
                  </div>
                </div>
              </div>
            </div>
      </div>
  </section>
    )
}
export default FaqService;