import React, { useEffect, useState }  from 'react'; 
import axios from 'axios';
import { Link } from 'react-router-dom';
import config from '../../config';

const LogoService = () =>{
    const [logoServiceJsonData, setLogoServiceJsonData] = useState([]); 

    async function fetchLogoServiceFunc() {
        try {
            const responseLogoPage = await axios.get(config.API_URL + config.PAGES_URL);
            setLogoServiceJsonData(responseLogoPage.data[6].sections[0]);
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }
    useEffect(() => {
        fetchLogoServiceFunc();
    }, []);      
    return(
        <>
            <section className="logo-service-area">
                <div className="container">
                    <div className="section-header text-center">
                        <h3>{logoServiceJsonData.title}</h3>
                    </div>
                    {/* slider start  */}
                    {logoServiceJsonData && logoServiceJsonData.sliderImages && logoServiceJsonData.sliderImages.length > 0 ? (
                        logoServiceJsonData.sliderImages.map(logoServiceItemData => (
                            <div className="swiper-slide">
                                <img src={config.FILE_URL + logoServiceItemData.Image} alt="" />
                            </div>                                               
                        ))
                    ):( console.log())}   
                    {/* slider end  */}

                    {logoServiceJsonData && logoServiceJsonData?.items && logoServiceJsonData.items.length > 0 ? (
                        logoServiceJsonData.items.map((logoSliderData, index) => (
                            <div className={`swiper-slide`} key={index}>
                                {index % 2 === 0 ? (
                                <div className="row h-100 align-items-center">
                                    <div className="col-md-6">
                                        <img src={config.FILE_URL + logoSliderData.thumbImage} alt="" className="img-fluid text-end" />
                                    </div>
                                    <div className="col-md-6">
                                        <div className="logo-details">
                                            <h3>{logoSliderData.title}</h3>
                                            <div dangerouslySetInnerHTML={{__html : logoSliderData.content}}></div>
                                        </div>
                                    </div>                
                                </div>
                                ) : (
                                <div className="row h-100 align-items-center">
                                    <div className="col-md-6 d-sm-block d-none">
                                        <div className="logo-details">
                                            <h3>{logoSliderData.title}</h3>
                                            <div dangerouslySetInnerHTML={{__html : logoSliderData.content}}></div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <img src={config.FILE_URL + logoSliderData.thumbImage} alt="" className="img-fluid text-end" />
                                    </div>
                                    <div className="col-md-6 d-sm-none d-block">
                                        <div className="logo-details">
                                            <h3>{logoSliderData.title}</h3>
                                            <span dangerouslySetInnerHTML={{__html : logoSliderData.content}}></span>
                                        </div>
                                    </div>
                                </div>
                                )}
                            </div>
                        ))
                    ) : ( console.log() )}
                    <div className="banner-offer mb-5 text-center">
                        <Link to="../contactpage" className="btn-service btn">Contact Us</Link>
                    </div>
                </div>    
            </section>
        </>
    
    )
}
export default LogoService;