import React, { useEffect, useState }  from 'react'; 
import axios from 'axios';
import { Swiper, SwiperSlide} from 'swiper/react';
// import required modules
import { Autoplay } from 'swiper/modules'
import 'swiper/css';
import config from '../../config';
const SeoFeatured = () =>{
    const [seoSocialJsonData, setSeoSocialJsonData] = useState([]); 

    async function fetchSeoSocialFunc() {
        try {
            const responseSeoSocial = await axios.get(config.API_URL + config.PAGES_URL);
            console.log('response_about_', responseSeoSocial.data[8].sections[0].featuredslider);
            setSeoSocialJsonData(responseSeoSocial.data[8].sections[0]);
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }
    useEffect(() => {
        fetchSeoSocialFunc();
    }, []);
    return(
        <>
            <section className="feature-slider mt-5">
                <div className="container">
                    <div className="section-header text-center">
                        <h3>{seoSocialJsonData.title}</h3>
                    </div>
                    <div className="slider-area">
                        <div className="swiper featureSwiper my-5">
                        <Swiper
                                spaceBetween={0}
                                slidesPerView={6}
                                speed={500}
                                autoplay={{
                                delay: 2000,
                                disableOnInteraction: false,
                                }}
                                loop={true}
                                modules={[Autoplay]}
                                className="swiper mySwiper"
                                > 
                                    {seoSocialJsonData && seoSocialJsonData.featuredslider && seoSocialJsonData.featuredslider.length > 0 ? (
                                    seoSocialJsonData.featuredslider.map(seoSocialData => (
                                        <SwiperSlide className="swiper-slide d-flex align-items-center justify-content-center" key={seoSocialData.id}>
                                        <img src={config.FILE_URL + seoSocialData.image} alt="" className="img-fluid" />
                                        </SwiperSlide>
                                    ))
                                ): null}                                         
                            </Swiper>
                        </div>
                    </div>
                </div>    
            </section>  
        </>
    )
}
export default SeoFeatured;