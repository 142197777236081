import React, { useEffect, useState }  from 'react'; 
import axios from 'axios';
import { Swiper, SwiperSlide} from 'swiper/react';
import { Autoplay } from 'swiper/modules'
import 'swiper/css';
import config from '../../config';
const SmmFeatured = () =>{
    const [smmSmmJsonData, setSmmSocialJsonData] = useState([]);
 
    async function fetchSmmSocialFunc() {
        try {
            const responseSmm = await axios.get(config.API_URL + config.PAGES_URL);
            setSmmSocialJsonData(responseSmm.data[7].sections[0]);
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }
    useEffect(() => {
        fetchSmmSocialFunc();
    }, []);     
    return(
        <>
            <section className="feature-slider mt-5">
                <div className="container">
                    <div className="section-header text-center">
                        <h3>{smmSmmJsonData.title}</h3>
                    </div>
                    <div className="slider-area">
                        <div className="swiper featureSwiper my-5">
                            <div className="swiper-wrapper">
                            <Swiper
                                spaceBetween={0}
                                slidesPerView={6}
                                speed={500}
                                autoplay={{ delay: 2000, disableOnInteraction: false}}
                                loop={true}
                                modules={[Autoplay]}
                                className="swiper mySwiper"
                            > 
                            {smmSmmJsonData && smmSmmJsonData.featuredslider && smmSmmJsonData.featuredslider.length > 0 ? (
                                smmSmmJsonData.featuredslider.map(smmData => (
                                    <SwiperSlide className="swiper-slide d-flex align-items-center justify-content-center" key={smmData.id}>
                                        <img src={config.FILE_URL + smmData.image} alt={smmData.title} className="img-fluid" />
                                    </SwiperSlide>
                                ))
                            ):( console.log())}                                        
                            </Swiper>                        
                            </div>
                        </div>
                    </div>
                </div>    
            </section>
        </>
    )
}
export default SmmFeatured;