import React, { useEffect, useState }  from 'react';
import axios from 'axios';
import 'swiper/css';
import { Link } from 'react-router-dom';
import config from '../../config';
const UiUxBannerFunc = () =>{
    const [uiuxPageJasonData, setUiuxJasonData] = useState([]);
    async function fetchUiuxPage() {
        try {
            const responseBanner = await axios.get(config.API_URL + config.PAGES_URL);    
            setUiuxJasonData(responseBanner.data[5]);
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }
    useEffect(() => {
        fetchUiuxPage();
    }, []);    

    return(
        <>
            <section className="banner-area">
                    <div className="banner-body">
                        <div className="container">
                            <div className="row">
                                    <div className="col-md-5">
                                        <div className="banner-left">
                                            <div className="">
                                                <h1 className="banner-title">{uiuxPageJasonData.title}</h1>
                                                <p className="banner-details">{uiuxPageJasonData.content}</p>
                                                <div className="banner-offer mb-5">
                                                    <Link to="../contactpage" className="banner-trial-btn btn">
                                                        Place Order 
                                                        <img src={config.FILE_URL + "images/top-page/banner/order_icon.svg"} alt="" className="color-icon" /> 
                                                        <img src={config.FILE_URL + "images/top-page/banner/order_icon_white.svg"} alt="" className="white-icon" />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>                            
                                    </div>
                                    <div className="col-md-7">
                                        <div className="banner-right text-end">
                                            <img src={config.FILE_URL + uiuxPageJasonData.thumbImage} alt="" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                            </div>   
                        </div>
            </section>
        </>
    )
}
export default UiUxBannerFunc;