import React, { useEffect, useState }  from 'react';
import axios from 'axios';
import config from '../../config';
const ShortAbout = () =>{
    const [shortInfoJasonData, setShortInfoJasonData] = useState([]);

    async function fetchShortInfo() {
        try {
            const response = await axios.get(config.API_URL + config.SHORT_INFO_URL);
            setShortInfoJasonData(response.data);
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }
    useEffect(() => {
        fetchShortInfo();
      }, []);
    return(
        <>
            <section className="short-about-area">
                    <div className="container">
                        <div className="section-header">
                            <h1>{shortInfoJasonData.title}</h1>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <img src={config.FILE_URL+"images/top-page/short-about/Igniting Creativity.gif"} alt="" className="img-fluid" />
                            </div>
                            <div className="col-md-6">
                                <div className="short-details">
                                    <p>{shortInfoJasonData.description1}</p>
                                    <p>{shortInfoJasonData.description2}</p>
                                    <p>{shortInfoJasonData.description3}</p>
                                    <p>{shortInfoJasonData.description4}</p>
                                    <p><strong> {shortInfoJasonData.descriptiontitle}</strong> {shortInfoJasonData.description5}</p>
                                </div>
                            </div>
                        </div>
                    </div>
            </section>
        </>
        
    )
}
export default ShortAbout;