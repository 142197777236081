import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded'; // Import imagesLoaded
import config from '../../config';
function IsotopeDemo() {
  const [jsonData, setJsonData] = useState([]);
  const [activeButton, setActiveButton] = useState('*');
  const isotopeRef = useRef(null);
  const iso = useRef(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(config.API_URL + config.PORTFOLIO_URL);
        setJsonData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (jsonData.length > 0 && isotopeRef.current) {
      // Initialize Isotope when jsonData is available
      iso.current = new Isotope(isotopeRef.current, {
        itemSelector: '.grid-item',
        layoutMode: 'fitRows',
      });

      // Use imagesLoaded to wait for images to load
      const imgLoad = imagesLoaded(isotopeRef.current);
      imgLoad.on('progress', () => {
        iso.current.layout(); // Trigger Isotope layout after images are loaded
      });

      // Cleanup Isotope instance when the component unmounts
      return () => {
        if (iso.current) {
          iso.current.destroy();
        }
      };
    }
  }, [jsonData]);

  const uniqueTypes = [...new Set(jsonData.map((item) => item.type))];

  const filter = (filterValue) => {
    if (iso.current) {
      iso.current.arrange({ filter: filterValue });
    }
    setActiveButton(filterValue);
  };

  return (
    <div>
      <section className="portfolio-area">
        <div className="container">
          <div className="section-header">
            <h3>Visual Portfolio</h3>
            <p>Captivating designs that impress. Explore our Visual Portfolio.</p>
          </div>
          <div className="iso-nav">
            <ul>
              <li
                className={activeButton === '*' ? 'active' : ''}
                onClick={() => filter('*')}
              >
                All
              </li>
              {uniqueTypes.map((type) => (
                <li
                  key={type}
                  className={
                    activeButton === `.${type.replace(/\s+/g, '')}` ? 'active' : ''
                  }
                  onClick={() => filter(`.${type.replace(/\s+/g, '')}`)} 
                >
                  {type}
                </li>
              ))}
            </ul>
          </div>
          <div className="main-iso row grid" ref={isotopeRef}>
            {jsonData.map((item) => (
              <div
                key={item.id}
                className={`item grid-item personal ${item.type.replace(/\s+/g, '')} post col-md-4 col-sm-6`}
              >
                <img src={config.FILE_URL + item.thumbImg} alt={item.altText} className='img-fluid px-3' />
              </div>
            ))}
          </div>
          <div className="text-center">
            <Link to="../contactpage" className="btn btn-talk">
              Let's Talk
            </Link>
          </div>
        </div>
      </section>    
  </div>
  );
}

export default IsotopeDemo;
