import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import { Link } from 'react-router-dom';
import config from '../../config';
const ServiceArea = () =>{
    const [servicJsonData, setServiceJsonData] = useState ([]);
    async function fetchService() {
        try {
            const response = await axios.get(config.API_URL + config.SERVICE_URL);
            setServiceJsonData(response.data);
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }
    useEffect(() => {
        fetchService();
      }, []);
    return(
        <>
            <section className="service-area">
                <div className="container">
                    <div className="section-header">
                        <h1>Phicsart Services</h1>
                    </div>
                    <div className="service-bg">
                        <div className="row m-0 p-2">
                        {servicJsonData.map(item => (
                                <div className="col-lg-4 col-md-6" key={item.id}>
                                    <div className="service-card">
                                        <ReactCompareSlider
                                            itemOne={<ReactCompareSliderImage src={config.FILE_URL+item.beforeImage} srcSet={config.FILE_URL+item.beforeImage} alt="Image one" />}
                                            itemTwo={<ReactCompareSliderImage src={config.FILE_URL+item.afterImage} srcSet={config.FILE_URL+item.afterImage} alt="Image two" />}
                                            />
                                        <div className="service-details">
                                            <h1>{item.title}</h1>
                                        <div dangerouslySetInnerHTML={{ __html: item?.content?.substring(0, 130) }}></div>
                                            <Link to={`/service_details/${item.id}`}>Explore <i className="fa-solid fa-right-long"></i></Link> 
                                        </div>
                                    </div>
                                </div>         
                            ))} 
                        </div>
                        <div className="text-center">
                            <Link to={config.BASE_URL+"./servicepage"} className="btn btn-service">See All Service</Link>
                        </div>
                    </div>
                </div>    
            </section>    
        </>
        
    )
}
export default ServiceArea;