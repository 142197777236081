import axios from 'axios';
import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import config from '../config';
const Footer = () =>{
  const [siteInfoJsonData, setSiteInfoJsonData] = useState([]);
  const [servicJsonData, setServiceJsonData] = useState ([]);

  async function fetchService() {
    try {
    const response = await axios.get(config.API_URL + config.SERVICE_URL);
    setServiceJsonData(response.data);
    } catch (error) {
    console.error('Error reading JSON file:', error);
    }
}
  // siteinfo function 
  async function fetchSiteInfo() {
    try {
        const response = await axios.get(config.API_URL + config.SITE_INFO_URL);
        setSiteInfoJsonData(response.data);
    } catch (error) {
        console.error('Error reading JSON file:', error);
    }
  }
    useEffect(() => {
      fetchSiteInfo();
      fetchService(); 
      $(document).ready(function () {
        $(document).on('click', '.bar-icon', function () {
          $(".menu-unorder-list").addClass("active");
          $(".menu-overlay").addClass("active");
          $("body").addClass("overflow");
        });
        $(document).on('click', '.menu-overlay', function () {
          $(".menu-unorder-list").removeClass("active");
          $(".menu-overlay").removeClass("active");
          $("body").removeClass("overflow");
        });
        $(document).on('click', '.close-icon', function () {
          $(".menu-unorder-list").removeClass("active");
          $(".menu-overlay").removeClass("active");
          $("body").removeClass("overflow");
        });

      });
     
      }, []);
    return(
      <div>
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-lg-3">
                        <div className="footer-about">
                            <div className="footer-logo">
                                <img src={config.FILE_URL + siteInfoJsonData.logo} alt="" className="img-fluid" /> &nbsp;
                                {siteInfoJsonData.name}
                            </div>
                            <div className="footer-location d-flex">
                                <div className="me-3">
                                <img src={config.FILE_URL+"images/top-page/footer/location.svg"} alt="" className="img-fluid" />
                                </div>
                                <p>{siteInfoJsonData.location}</p>
                            </div>
                            <div className="footer-location d-flex">
                                <div className="me-3">
                                <img src={config.FILE_URL+"images/top-page/footer/envolp.svg"} alt="" className="img-fluid" />
                                </div>
                                <p>
                                {siteInfoJsonData.mail}
                                </p>
                            </div>
                            <div className="footer-location d-flex">
                                <div className="me-3">
                                <img src={config.FILE_URL+"images/top-page/footer/phone.svg"} alt="" className="img-fluid" />
                                </div>
                                <p>
                                {siteInfoJsonData.phone}
                                </p>                            
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-2">
                        <div className="footer-link-header">
                            <h3>Company</h3>
                        </div>
                        <ul className="footer-link">
                            <li><Link to="./about">About us</Link></li>
                            <li><Link to="./terms">Terms & Condition</Link></li>
                            <li><Link to="./privacy">Privacy Policy</Link></li>
                            <li><Link to="./career">Career</Link></li>
                        </ul>
                    </div>
                    <div className="col-md-6 col-lg-2">
                        <div className="footer-link-header">
                            <h3>Top Services</h3>
                        </div>
                        <ul className="footer-link">
                        {servicJsonData.map(item => (
                        item.id <= 5 ? (
                            <li key={item.id}>
                                <Link to={`/service_details/${item.id}`}>    
                                    <span>
                                        {item.title}
                                    </span>
                                </Link>
                            </li>
                            ) : null
                        ))} 
                        </ul>
                    </div>
                    <div className="col-md-6 col-lg-5">
                        <div className="footer-link-header">
                            <h3>Stay Up to Date</h3>
                        </div>
                        <form method='post'>
                            <div className="input-group mb-3">
                                <input type="email" className="form-control" placeholder="Enter your email !" id="subscribe" name="subscribe" />
                                <button type="submit" className="btn btn-subscribe">Subscribe</button>
                            </div>
                        </form>
                        <div className="subscribe-details">
                            <p>We Send you update about PhicsArt Service, Blog etc tips for your daily workflow</p>
                        </div>
                        <div className="social-link d-flex flex-wrap">
                            <a href="https://www.facebook.com/profile.php?id=61550082346165" target="_blank" rel="noreferrer" className="facebook"><i className="fa-brands fa-facebook-f"></i></a>
                            <a href="https://www.youtube.com/channel/UCDgpBnVbbksnr5HslMfT1cQ" rel="noreferrer" target="_blank" className="youtube"><i className="fa-brands fa-youtube"></i></a>
                            <a href="https://www.linkedin.com/in/phicsart/" rel="noreferrer" target="_blank" className="linkdin"><i className="fa-brands fa-linkedin-in"></i></a>
                            <a href="https://www.pinterest.com/phicsart/"  rel="noreferrer" target="_blank" className="pinterest"><i className="fa-brands fa-pinterest-p"></i></a>
                            <a href="https://twitter.com/phicsart27" rel="noreferrer"  target="_blank" className="twitter"><i className="fa-brands fa-twitter"></i></a>
                            <a href="https://www.instagram.com/phicsart27/" rel="noreferrer" target="_blank" className="instagram">
                            <img src={config.FILE_URL+'images/top-page/footer/instagram.png'} alt=""/>
                            </a>
                            <a href="https://dribbble.com/phicsart27" rel="noreferrer" target="_blank" className="youtube"><i className="fa-brands fa-dribbble"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom-area">
                <div className="container ">
                    <div className="footer-bottom">
                        <div className="d-flex justify-content-between align-items-center">
                            <p>2023 All Right Reserve <i className="fa-solid fa-copyright"></i> PhicsArt </p>                        
                        </div>
                    </div>
                </div>
            </div>
        </footer>      
      </div>
    )
}
export default Footer;
