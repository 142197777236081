import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import config from '../../config';
const CareerDetails = () => {
    const { id } = useParams();
    const parsedId = parseInt(id, 10); // Parse id as an integer
    const idMinusOne = parsedId - 1; // Add 1 to the parsed id
    const [careerDetailsJsondata, setCareerDetailsJsondata] = useState ([]);
    const [siteInfoJsonData, setSiteInfoJsonData] = useState([]);

      // service array fetch function 
      
      const fetchCareerDetails = async () => {
        try {
            const response = await axios.get(config.API_URL + config.CAREER_URL);
            setCareerDetailsJsondata(response.data[idMinusOne]);
            } catch (error) {
        console.error('Error reading JSON file:', error);
        }
      }

      const fetchSiteInfo = async () => {
        try {
            const response = await axios.get(config.API_URL + config.SITE_INFO_URL);
            setSiteInfoJsonData(response.data);
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
      }
      // Function to toggle the second component
      const [showFirstComponent, setShowFirstComponent] = useState(true);

      const toggleComponent = () => {
        // Toggle between the two components by changing the state
        setShowFirstComponent(!showFirstComponent);
      };
     // eslint-disable-next-line react-hooks/exhaustive-deps
      useEffect(() => {
          fetchCareerDetails();
          fetchSiteInfo();
        }, [id]); // eslint-disable-next-line
        
  return (
    <div>  
        {showFirstComponent ? (
                <section className="career-details-area">
                    <div className="career-details">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="section-header">
                                        <p dangerouslySetInnerHTML={{ __html: careerDetailsJsondata.description }}></p>
                                        <h6 className="py-2 fw-bold">{careerDetailsJsondata.deadline }</h6>
                                        {careerDetailsJsondata.status === 1 ? (
                                            <button className="btn btn-career" onClick={toggleComponent}>Apply Now</button>
                                        ):(
                                            <button className="btn btn-career disabled" >Expire !</button>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="career-details-social">
                                        <h4>Share this on:</h4>
                                        <div className="social-link d-flex flex-wrap">
                                            <Link to="https://www.facebook.com/profile.php?id=61550082346165" target="_blank" rel="noreferrer" className="facebook"><i className="fa-brands fa-facebook-f"></i></Link>
                                            <Link to="https://www.linkedin.com/in/phicsart/" target="_blank" rel="noreferrer" className="linkdin"><i className="fa-brands fa-linkedin-in"></i></Link>
                                            <Link to="https://twitter.com/phicsart27"  target="_blank" rel="noreferrer" className="twitter"><i className="fa-brands fa-twitter"></i></Link>
                                        </div>
                                        <Link to="../career" className="btn btn-career my-4">View All Jobs</Link>
                                        <h4>Contact Information</h4>
                                        <div className="footer-about">
                                            <div className="footer-location d-flex">
                                                <div className="me-3">
                                                    <img src={config.FILE_URL + "images/top-page/footer/location.svg"} alt="" className="img-fluid" />
                                                </div>
                                                <p>{siteInfoJsonData.location}</p>
                                            </div>
                                            <div className="footer-location d-flex">
                                                <div className="me-3">
                                                    <img src={config.FILE_URL + "images/top-page/footer/envolp.svg"} alt="" className="img-fluid" />
                                                </div>
                                                <p>
                                                    {siteInfoJsonData.career_mail}
                                                </p>
                                            </div>
                                            <div className="footer-location d-flex">
                                                <div className="me-3">
                                                    <img src={config.FILE_URL + "images/top-page/footer/phone.svg"} alt="" className="img-fluid" />
                                                </div>
                                                <p>
                                                    {siteInfoJsonData.phone}
                                                </p>                            
                                            </div>
                                        </div>
                                        <h4>Follow Us:</h4>
                                        <div className="social-link d-flex flex-wrap">
                                            <Link to="https://www.facebook.com/profile.php?id=61550082346165" target="_blank" rel="noreferrer" className="facebook"><i className="fa-brands fa-facebook-f"></i></Link>
                                            <Link to="https://www.youtube.com/channel/UCDgpBnVbbksnr5HslMfT1cQ" target="_blank" rel="noreferrer" className="youtube"><i className="fa-brands fa-youtube"></i></Link>
                                            <Link to="https://www.linkedin.com/in/phicsart/" target="_blank" rel="noreferrer" className="linkdin"><i className="fa-brands fa-linkedin-in"></i></Link>
                                            <Link to="https://www.pinterest.com/phicsart/"  target="_blank" rel="noreferrer" className="pinterest"><i className="fa-brands fa-pinterest-p"></i></Link>
                                            <Link to="https://twitter.com/phicsart27"  target="_blank" rel="noreferrer" className="twitter"><i className="fa-brands fa-twitter"></i></Link>
                                            <Link to="https://www.instagram.com/phicsart27/" target="_blank" rel="noreferrer" className="instagram">
                                                <img src={config.FILE_URL+'images/top-page/footer/instagram.png'} alt=""/>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>        
                </section>
        ) : (
            <section className="apply-area mt-5">
                <div className="container">
                    <div className="apply-form-area">
                        <form action="">
                            <div className="service-type">
                                <h3>Apply Now!</h3>  
                                <div className="mb-3">
                                    <input type="text" className="form-control" name="" placeholder="Position" value={careerDetailsJsondata.postion} readOnly/>
                                </div>
                                <div className="mb-3">
                                    <input type="text" className="form-control" name="" placeholder="Name" />
                                </div>
                                <div className="mb-3">
                                    <input type="email" className="form-control" name="" placeholder="Email" />
                                </div>
                                <div className="mb-3">
                                    <input type="text" className="form-control" name="" placeholder="Subject" />
                                </div>
                                <div className="mb-3">
                                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="5" placeholder="Cover Latter"></textarea>
                                </div>
                                <h3>Upload File </h3> 
                                <div className="mb-3">
                                    <input className="form-control" id="formFileSm" type="file" />
                                    <label for="formFileSm" className="form-label">Upload .pdf file only</label>
                                </div>       
                                <div className="form-check mb-3">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    <label className="form-check-label" for="flexCheckDefault">
                                        Read the job details carefully or <strong onClick={toggleComponent} style={{ cursor: "pointer"}}>Get Back <i className="fa-solid fa-arrow-right-long"></i></strong>
                                    </label>
                                </div>  
                                    
                                <button className="btn btn-submit" type="submit">Send </button>
                            </div>                
                        </form>
                    </div>
                </div>
            </section>
        )}
    </div>
  )
}

export default CareerDetails;
