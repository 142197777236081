import React, { useEffect, useState }  from 'react'; 
import axios from 'axios';
import { Link } from 'react-router-dom';
import config from '../../config';

const SeoService = () =>{
    const [seoServiceJsonData, setSeoServiceJsonData] = useState([]);

    async function fetchSeoServiceFunc() {
        try {
            const responseSeoService = await axios.get(config.API_URL + config.PAGES_URL);
            setSeoServiceJsonData(responseSeoService.data[8].sections[1]);
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }
    useEffect(() => {
        fetchSeoServiceFunc();
    }, []);    
    return(
        <>
            <section className="smm-service-area">
                <div className="container">
                    <div className="section-header text-center">
                        <h3>{seoServiceJsonData.title}</h3>
                    </div>
                    <div className="row">
                        {seoServiceJsonData && seoServiceJsonData.items && seoServiceJsonData.items.length > 0 ? (
                            seoServiceJsonData.items.map(seoServiceData => (
                                <div className="col-md-6" key={seoServiceData.id}>
                                <div className="service-card">
                                    <div className="card-image">
                                        <img src={config.FILE_URL+seoServiceData.thumbImage} alt="" className="img-fluid" />
                                    </div>
                                    <div className="service-details">
                                        <h3>{seoServiceData.title}</h3>
                                        <p>{seoServiceData.content}</p>
                                    </div>
                                </div>
                            </div>
                            ))
                        ): null}
                    </div>
                    <div className="banner-offer mb-5 text-center">
                        <Link to="../contactpage" className="btn-service btn">Contact Us</Link>
                    </div>
                </div>
            </section> 
        </>
        
    )
}
export default SeoService;