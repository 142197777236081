import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ServiceDetailsBanner from './ServiceDetailsBanner';
import ServiceDetailsPortfolio from './ServiceDetailsPortfolio';
import SubService from './SubService';
import FaqService from './FaqServiceDetails';
import WorkflowArea from '../TopPage/WorkflowArea';
const  ServiceDetailsPage = function () {
    const location = useLocation();

    // Scroll to the top of the page when the route changes
    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top
    }, [location]);
    return(
        <div>
           <ServiceDetailsBanner />
           <ServiceDetailsPortfolio />
           <SubService />
           <WorkflowArea />
           <FaqService />
        </div> 
    )
}
export default ServiceDetailsPage;