import React, { useEffect, useState }  from 'react';
import axios from 'axios';
import config from '../../config';
const UiUxWorkFlowFunction = () =>{
    const [workflowJasonData, setWorkflowJasonData] = useState([]);

    async function fetchWorkFlowPage() {
        try {
            const responseWorkFlow = await axios.get(config.API_URL + config.PAGES_URL);   
            setWorkflowJasonData(responseWorkFlow.data[5].sections[1]);
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }
    useEffect(() => {
        fetchWorkFlowPage();
    }, []);    
    return(
        <>
            <section className="design-workflow-area">
                    <div className="container">
                        <div className="section-header text-center">
                            <h3>{workflowJasonData.title}</h3>
                            <p className="w-100">{workflowJasonData.content}</p>
                        </div>
                    <img src={config.FILE_URL + workflowJasonData.thumbImage} alt="" className="img-fluid" />
                    </div>
            </section> 
        </>
        
    )
}
export default UiUxWorkFlowFunction;