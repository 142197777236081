import React, { useEffect, useState }  from 'react'; 
import axios from 'axios';
import config from '../../config';
const LogoChoose = () =>{
    const [logoWhyChooseJsonData, setLogoWhyChooseJsonData] = useState([]); 
    async function fetchLogoWhyChooseFunc() {
        try {
            const responseLogoWhyChoose = await axios.get(config.API_URL + config.PAGES_URL);
            // console.log('response_about_', responseLogoWhyChoose.data[6].sections[1]);
            setLogoWhyChooseJsonData(responseLogoWhyChoose.data[6].sections[1]);
        } catch (error) {
            console.error('Error reading JSON file:', error);
        }
    }
    useEffect(() => {
        fetchLogoWhyChooseFunc();
    }, []);     
    return(
        <div>   
            <section className="logo-choose-area my-5">
                <div className="container">
                    <div className="section-header text-center">
                        <h3>{logoWhyChooseJsonData.title}</h3>
                    </div>
                    <div className="row mx-auto">
                        {/* items start  */}
                        {logoWhyChooseJsonData && logoWhyChooseJsonData.items && logoWhyChooseJsonData.items.length > 0 ? (
                            logoWhyChooseJsonData.items.map(whyChooseData => (
                                <div className="col-md-3 col-sm-6" key={whyChooseData.id}>
                                    <div className="expertise-card text-center">
                                        <img src={config.FILE_URL + whyChooseData.thumbImage} alt="" className="img-fluid" />
                                        <h6 className="py-4">{whyChooseData.title}</h6>
                                    </div>
                                </div>                                             
                            ))
                        ):( console.log())}   
                        {/* items end  */}
                    </div>
                </div>
            </section>
            <section className="utilize-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mx-auto animation-area">
                            <div className="animate-box-area">
                                <div className="adapt" style={{textTransform: 'capitalize'}}>
                                        Common Tools we Used !
                                </div>
                                <div className="animation-1">
                                    <div className="animat-1-box">
                                        <img src={config.FILE_URL + "images/uiux/figma.png"} className="ani-img-1" alt="" />
                                        <img src={config.FILE_URL + "images/uiux/figma.png"} className="ani-img-2" alt="" />
                                    </div>
                                </div>
                                <div className="animation-2">
                                    <div className="animat-2-box">
                                        <img src={config.FILE_URL + "images/uiux/skatch.png"} className="ani-img-1" alt="" />
                                        <img src={config.FILE_URL + "images/uiux/skatch.png"} className="ani-img-2" alt="" />
                                        <img src={config.FILE_URL + "images/uiux/ux.png"} className="ani-img-3" alt="" />
                                    </div>
                                </div>
                                <div className="animation-3">
                                    <div className="animat-3-box">
                                        <img src={config.FILE_URL + "images/uiux/ai.png"} className="ani-img-1" alt="" />
                                        <img src={config.FILE_URL + "images/uiux/ps.png"} className="ani-img-2" alt="" />
                                        <img src={config.FILE_URL + "images/uiux/ai.png"} className="ani-img-3" alt="" />
                                        <img src={config.FILE_URL + "images/uiux/ai.png"} className="ani-img-4" alt="" />
                                        <img src={config.FILE_URL + "images/uiux/ps.png"} className="ani-img-5" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
        </div>
    )
}
export default LogoChoose;