import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ServiceBanner from './ServiceBanner';
import MainService from './MainService';
import FaqService from './FaqService';
function ServicePage() {
    const location = useLocation();

    // Scroll to the top of the page when the route changes
    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top
    }, [location]);
    return(
        <div>
           <ServiceBanner />
           <MainService />
           <FaqService />
        </div> 
    )
}
export default ServicePage;