import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SeoBanner from './SeoBanner';
import SeoService from './SeoService';
import SeoFeatured from './SeoFeatured';
function Seo() {
    const location = useLocation();

    // Scroll to the top of the page when the route changes
    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top
    }, [location]);
    return(
        <div>
           <SeoBanner />
           <SeoFeatured />
           <SeoService />
        </div> 
    )
}
export default Seo;